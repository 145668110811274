import useRpc from "@/src/useRpc"
import LoadingSpinner from "@/components/LoadingSpinner"


// Handle loading of RPC data (and errors)
export default function RpcLoader ({method, args, render}) {

  const [data, error, reload] = useRpc(method, args)
  const loading = data === undefined

  return <>
    { error ?
      <>
        <div className="content">
          <pre style={{color: 'darkred', margin: '0 margin'}}>
            {error.message}
          </pre>
        </div>
    </>
    : loading ?
      <LoadingSpinner size="tiny"/>
    : !render ?
      // Omit `render` to dump the raw data into the UI (useful dev tool)
      <pre>
        {JSON.stringify(data, null, 2)}
      </pre>
    :
      // Uses 'render prop' (https://reactjs.org/docs/render-props.html)
      <>
        {render(data, reload)}
      </>
    }
  </>
}
