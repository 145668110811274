/* eslint @next/next/no-img-element: 0 */

import rpc from "@/src/rpc"
import classNames from "classnames"
import { useCallback, useEffect, useState } from "react"
import DeleteIcon from "./icons/legacy/DeleteIcon"
import RpcLoader from "./RpcLoader"
import Modal from "./Modal"
import { useDropzone } from 'react-dropzone'
import { sendMessage, useMessage } from '@/src/useMessage'
import { sleep, uploadFileWithProgress } from '@/src/misc'
import ProgressBar from "./ProgressBar"
import useDeviceInfo from "@/src/useDeviceInfo"
import PlusIcon from "./icons/PlusIcon"
import useCurrentOrg, { refreshCurrentOrg } from "@/src/useCurrentOrg"


const shadowFilter = 'drop-shadow(0px 1px 1px #000000CC)'
const glowFilter   = 'drop-shadow(0px 0px 2px #FFFFFFFF)'


export default function BrandSettings ({allowReset}) {

  const currentOrg = useCurrentOrg()

  async function logoSetup (logo) {
    const taskId = logo.setupTaskId
    let task = null
    const maxWait = 60 // seconds

    for (let i = 0; i < maxWait; i++) {
      console.log('checking task...')
      task = await rpc('backgroundTasks.find', {id: taskId})

      if (task.status === 'completed') {
        // Reload the logo to get the post-setup version
        return await rpc('logos.find', {id: logo.id})
      }

      const keepWaiting = (
        task.status === 'running' ||
        task.status === 'queued'
      )

      if (keepWaiting) {
        await sleep(1000)
      } else {
        break
      }
    }

    console.log('Task Failed')
    console.log(task)
    throw Error('Logo setup failed')
  }

  const [addingLogo, setAddingLogo] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [editingLogo, setEditingLogo] = useState(null)
  const [editingColor, setEditingColor] = useState(null)
  const [brandingNotesVisible, setBrandingNotesVisible] = useState(false)

  useMessage('uploadLogo', () => setAddingLogo(true))

  function cloneColorizedLogo(logo) {
    const newLogo = {...logo, style: 'backdrop', clone: true}
    setEditingLogo(newLogo)
  }

  function editColor(color) {
    setEditingColor(color)
  }

  function reloadImages () {
    refreshCurrentOrg()
    sendMessage('brandingStarted')
  }

  return <>
    <section className="settings">

      <RpcLoader method="brandColors.all" render={(colors, reloadColors) => {
        return <>
          <RpcLoader method="logos.all" render={(logos, reloadLogos) => {

            const hasLogos = logos.length > 0
            const allColorized = logos.filter(l => l.style === 'colorize').length === logos.length

            const showRemoveAllBranding = (
              (colors.length > 0 || logos.length > 0) &&
              (!!currentOrg?.demo || !!allowReset)
            )

            async function removeAllBranding() {
              await rpc('logos.removeAll')
              await rpc('brandColors.removeAll')
              reloadLogos()
              reloadColors()
              reloadImages()
            }

            return <>

              <Modal visible={brandingNotesVisible} onClickOutside={() => setBrandingNotesVisible(false)}>
                <div className="content double-padded" style={{maxWidth: 480}}>
                  <h2>Our Approach to Branding</h2>
                  <p>
                    Social Jazz automatically adds your logo and colors, but it
                    won&apos;t brand <em>EVERY</em> social media post, because:
                  </p>
                  <ul>
                    <li>Some images look bad with a logo</li>
                    <li>Branding feels tacky on some topics</li>
                    {/* <li>We don&apos;t alter images you upload</li> */}
                    <li>Having a mix of unbranded posts adds authenticity</li>
                  </ul>
                  <p>
                    In short, we use branding when it makes sense, to show the
                    world that you are: professional, reliable, and trustworthy.
                  </p>
                  <div className="padded center-text">
                    <button className="button" onClick={() => setBrandingNotesVisible(false)}>
                      Roger That 👍
                    </button>
                  </div>
                </div>
              </Modal>

              <div className="spread">
                <h3 style={{margin: '0.35rem 0', lineHeight: '2rem'}}>
                  Your Logo{logos.length > 1 ? 's' : ''}
                </h3>
              </div>

              { hasLogos && <>
                <div className="logo-list">
                  {logos.map(logo => {
                    const scalingFactor = 100 / (logo.width * logo.scale)

                    const width = logo.width * logo.scale
                    const height = logo.height * logo.scale
                    let padding = logo.bgPadding * scalingFactor
                    let background = RGBA(logo.bgColor, logo.bgOpacity)
                    let borderRadius = logo.bgRounding * scalingFactor + 'px'

                    if (logo.style !== 'backdrop') {
                      background = ''
                      borderRadius = ''
                      padding = 0
                    }

                    return (
                      <div
                        key={logo.id}
                        className={classNames( "logo-container clickable", { dark: logo.style === 'glow', } )}
                        onClick={() => setEditingLogo(logo)}
                      >
                        <div
                          className="logo-background"
                          style={{ borderRadius, padding, background }}
                        >
                          <img
                            alt=""
                            className={classNames('logo', { [logo.style]: true })}
                            src={logo.url}
                            style={{ width }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>}

              { (hasLogos && !!allColorized) &&
                <p className="warning">
                  <b>Tip:</b> <a className="link" onClick={() => cloneColorizedLogo(logos[0])}>Add a backup style</a>, for
                  posts where the logo can&apos;t be &quot;Colorized&quot;.
                </p>
              }

              { hasLogos ?
                <p className="subtle note">
                  Add as many logos as you like, and we will pick the
                  one that works best for each image.
                </p>
              :
                <>
                  <p className="subtle note">
                    We&apos;ll automatically add it to your posts.
                  </p>
                </>
              }

              <button
                className={classNames("button", {'is-primary': !logos.length})}
                style={{width: '100%'}}
                onClick={() => setAddingLogo(true)}
              >
                { logos.length ? 'Add another Logo' : 'Upload your Logo'}

              </button>

              <Modal visible={addingLogo || editingLogo}>
                <div className="logo-editor-modal">
                  { addingLogo ?
                    <div className='padded'>
                      <LogoDropZone
                        onStart={() => setUploading(true)}
                        onStop={() => setUploading(false)}
                        onUpload={async logo => {
                          try {
                            logo = await logoSetup(logo)
                            setEditingLogo(logo)
                          } catch (err) {
                            sendMessage('error', "Logo setup failed.")
                          } finally {
                            reloadLogos()
                            reloadColors()
                            setAddingLogo(false)
                            reloadImages()
                          }
                        }
                      }/>

                      <div className="button-container" style={{marginTop: '1rem'}}>
                        <button className="button" onClick={() => setAddingLogo(false)} disabled={uploading}>
                          Cancel
                        </button>
                        </div>
                    </div>
                  :
                    <div className="content">
                      <LogoEditor
                        logo={editingLogo}
                        afterCancel={() => setEditingLogo(null)}
                        afterSave={() => {
                          setEditingLogo(null)
                          reloadLogos()
                          reloadImages()
                        }}
                      />
                    </div>
                  }
                </div>
              </Modal>

              <hr/>

              <>
                <h3 style={{marginTop: '1rem'}}>
                  Brand Colors
                </h3>

                <div className="color-list">
                  {colors.map(color => (
                    <div className="color-container clickable" key={color.id} onClick={e => editColor(color)}>
                      <div className="flex-row">
                        <div className="color-swatch" style={{background: color.hexValue}}>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div onClick={e => editColor({hexValue: ''})} className="color-container clickable" >
                    <div className="flex-row gap-3">
                      <div className="color-swatch flex-row flex-center blue flex-no-shrink">
                        <PlusIcon/>
                      </div>
                      { colors.length === 0 &&
                        <div className="gray small left pr-2">
                          Add your colors to the post images.
                        </div>
                      }
                    </div>
                  </div>
                </div>

                { showRemoveAllBranding &&
                  <>
                    <hr/>
                    <a className="block my-4 center red hover_red text-sm opacity-10 transition hover_opacity-100" onClick={removeAllBranding}>
                      Reset branding (delete logos and colors)
                    </a>
                  </>
                }

                <Modal visible={editingColor}>
                  <ColorEditor
                    color={editingColor}
                    onClose={() => editColor(null)}
                    onChange={() => {
                      reloadColors()
                      reloadImages()
                    }}
                  />
                </Modal>
              </>

            </>
          }} />

        </>
      }} />

      <hr/>

      <p className="gray small center-text">
        <b>Note:</b> <a className="link" onClick={() => setBrandingNotesVisible(true)}>
          Some images won&apos;t get branding.
        </a>
      </p>

    </section>


    <style jsx>{`
      section {
        margin: 1rem 0 4rem;
        padding: 0 1rem;
        padding-bottom: 15rem;
        background: white;
      }
      .warning {
        padding: 0.5rem 1rem;
        background-color: #ffa;
        border: 1px solid #ee3;
        font-size: 0.9rem;
      }
      .logo-list {
        margin: 0 -0.5rem 1rem;
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 0.5rem
      }
      .color-container {
        align-items: center;
        margin: 0.2rem;
        padding: 0.08rem;
        text-align: center;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-items: center;
        display: inline-block;
        vertical-align: top;
      }
      .color-list {
        margin-left: -0.2rem;
      }
      .color-swatch {
        display: flex;
        float:left;
        width: 32px;
        height: 32px;
        border-radius: 0.25rem;
        box-shadow: 0 0.5px 3px rgb(0 0 0 / 25%);
      }
      .logo {
        backface-visibility: hidden;
      }
      .logo-container {
        padding: 1rem;
        background: #DDD;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .logo-container.dark {
        background: #222;
      }
      .logo.colorize {
        filter: brightness(0) saturate(100%);
      }
      .logo.glow {
        filter: ${glowFilter};
      }
      .logo.shadow {
        filter: ${shadowFilter};
        box-shadow: none !important;
      }
      .logo-editor-modal {
        padding: 1rem;
      }
      .logo-background {
        position: relative;
        width: fit-content;
        max-width: 300px;
        display: grid;
      }
    `}</style>
  </>
}


function ColorEditor({color, onClose, onChange}) {

  const VALID_COLOR = /^#[0-9a-fA-F]{6}$/

  const [hexValue, setHexValue] = useState(color.hexValue)
  const isValid = normalizedHexColor(hexValue).match(VALID_COLOR)

  async function cancel () {
    onClose()
  }


  function normalizedHexColor(hex) {
    if (!hex) return hex
    if (hex[0] === '#') hex = hex.substring(1)

    // Allow 3-character shorthand (e.g. #F0C means #FF00CC)
    if (hex.match(/^[0-9a-fA-F]{3}$/) ) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    return '#' + hex
  }

  async function remove() {
    await rpc('brandColors.del', {id: color.id})
    onChange()
    onClose()
  }

  async function save () {
    const hex = normalizedHexColor(hexValue)
    if (color.id) {
      await rpc('brandColors.set', {id: color.id, hexValue: hex})
    } else {
      await rpc('brandColors.add', {hexValue: hex})
    }
    onChange()
    onClose()
  }

  function handleSubmit (e) {
    e.preventDefault()
    if (isValid) save()
  }

  return <>
    <section className="content">
      <div className="flex-row spread">
        <h3>
          { color.id ? 'Edit' : 'New' } Color
        </h3>
        { !!color.id &&
          <span className="color-remove" onClick={remove} title="Delete Color">
            <DeleteIcon/>
          </span>
        }
      </div>
      <form className='color-control' onSubmit={handleSubmit} style={{minWidth: 220}}>
        <label className="center-vertically flex-row gap-1">
          <input
            className="color-picker-input"
            type="color"
            value={normalizedHexColor(hexValue)}
            onChange={e => setHexValue(e.target.value)}
          />&nbsp;
          <div className="gray">
            {hexValue ?
              <>{hexValue.toUpperCase()}</>
            :
              <>&larr; Tap to pick a color</>
            }
          </div>
        </label>
      </form>

      <div className="button-container" style={{marginTop: '1rem'}}>
        <button className="button is-primary" onClick={save} disabled={!hexValue}>
          Save
        </button>
        <button className="button" onClick={cancel} >
          Cancel
        </button>
      </div>
    </section>

    <style jsx>{`
      section {
        padding: 1rem;
        background: white;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
      }
      h3 {
        margin: 0.5rem 0;
      }
      .color-control {
        padding: 0.5rem;
      }
      .color-picker-input {
        height: 40px;
        width: 40px;
      }
      .color-remove {
        background-color: #ffffffcc;
        padding: 0px 6px 4px;
        border-radius: 2px;
        cursor: pointer;
        vertical-align: top;
        border: 1px solid #ffaaaa99;
      }
      .color-remove:hover {
        background-color: #ffcccccc;
        border-color: #ffaaaa;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      }
      button {
        width: 50%;
      }
    `}</style>
  </>
}




function LogoEditor({logo, afterSave, afterCancel}) {

  const [ style,      setStyle      ] = useState(logo.style)
  const [ bgColor,    setBgColor    ] = useState(logo.bgColor)
  const [ bgOpacity,  setBgOpacity  ] = useState(logo.bgOpacity)
  const [ bgPadding,  setBgPadding  ] = useState(logo.bgPadding)
  const [ bgRounding, setBgRounding ] = useState(logo.bgRounding)

  const [ showBackdropSettings, setShowBackdropSettings ] = useState(false)

  function toggleBackdropSettings () {
    // Scroll the modal contents on small devices (e.g. iPhone SE)
    if (!showBackdropSettings && typeof document !== 'undefined') {
      setTimeout(() => {
        const modal = document.getElementsByClassName('modal')[0]
        modal.scrollTo({top: 300, behavior: "smooth"})
      }, 600)
    }
    setShowBackdropSettings(!showBackdropSettings)
  }

  const backdrop = style === 'backdrop'

  const margin = backdrop ? parseInt(bgPadding, 10) : 0

  // This is the biggest size (currently allowed by our logo areas
  const maxWidth  = 520
  const maxHeight = 150
  const minWidth  = 50
  const minHeight = 50

  const maxLogoWidth = maxWidth - margin * 2
  const maxLogoHeight = maxHeight - margin * 2

  const maxLogoScale = Math.min(maxLogoWidth / logo.width, maxLogoHeight / logo.height)

  const [scale, setScale] = useState(Math.min(maxLogoScale, logo.scale))
  const [scaleMaxed, setScaleMaxed] = useState(scale >= maxLogoScale * 0.99)

  const maxScale = Math.min(maxWidth / logo.width, maxHeight / logo.height)
  const minScale = Math.min(minWidth / logo.width, minHeight / logo.height)

  const hasChanges = (
    logo.clone ||
    scale      !== logo.scale       ||
    style      !== logo.style       ||
    bgColor    !== logo.bgColor     ||
    bgOpacity  !== logo.bgOpacity   ||
    bgPadding  !== logo.bgPadding   ||
    bgRounding !== logo.bgRounding
  )

  const backstage = typeof window !== 'undefined' && window.location.pathname.startsWith('/backstage/')

  useEffect(() => {
    // When changing padding or style, increase scale (if it was previously maxed)
    if (scaleMaxed) setScale(maxLogoScale)

    // As padding and backdrop change, the scale may need to be reduced
    if (scale > maxLogoScale) setScale(maxLogoScale)
  }, [scale, scaleMaxed, maxLogoScale, setScale, style, bgPadding])

  function changeScale(newScale) {
    const scaleValue = Math.min(maxLogoScale, newScale)
    setScale(scaleValue)
    setScaleMaxed(scaleValue >= maxLogoScale * 0.99)
  }

  async function handleDelete () {
    if (!confirm('Are you sure you want to delete this logo?')) {
      return
    }
    await rpc('logos.del', {id: logo.id})
    afterSave()
  }

  async function handleSave () {
    const data = { style, scale, bgColor, bgOpacity, bgPadding, bgRounding }
    if (logo.clone) {
      await rpc('logos.clone', { id: logo.id, data})
    } else {
      await rpc('logos.set', { id: logo.id, data})
    }
    afterSave()
  }

  function handleCancel () {
    afterCancel()
  }


  // CSS filter for colorize courtesy of https://codepen.io/sosuke/pen/Pjoqqp
  const shiftToBlack = 'brightness(0) saturate(100%) '
  const logoProps = { scale, style, bgColor, bgOpacity, bgPadding, bgRounding }
  const previews = [
    {
      logo,
      logoProps,
      align: 'left',
      vAlign: 'bottom',
      logoX: 55,
      logoY: 580,
      photoUrl: `/img/logo-test-light.jpg`,
      primaryColor: '#9f622d',
      colorizeFilter: shiftToBlack + `invert(43%) sepia(13%) saturate(2212%) hue-rotate(346deg)`,
    },
    {
      logo,
      logoProps,
      align: 'center',
      vAlign: 'middle',
      logoX: 806,
      logoY: 510,
      photoUrl: `/img/logo-test-dark.jpg`,
      primaryColor: '#FFF47C',
      colorizeFilter: shiftToBlack + `invert(86%) sepia(37%) saturate(516%) hue-rotate(9deg) brightness(108%) contrast(104%)`,
    },
  ]

  return <>
    <div className="flex-row spread">
      { logo.clone ?
        <h3>Backup Logo</h3>
      :
        <h3>Logo Preview</h3>
      }
      <span className="remove-logo" onClick={handleDelete} title="Remove this Logo">
        <DeleteIcon/>
      </span>
    </div>

    <LogoPreview {...previews[0]} key={previews[0].photoUrl}/>
    <div className="help style-tip">
      Pick a style that looks good on light &uarr; and dark &darr; posts.
    </div>
    <LogoPreview {...previews[1]} key={previews[1].photoUrl}/>

    <div className='center-vertically'>
      <label className="main-label" style={{fontSize: '1.05rem'}}>
        Size:
      </label>
      <input
        type="range"
        value={scale}
        min={minScale}
        max={maxLogoScale}
        step={(maxLogoScale - minScale) / 1000}
        onChange={e => changeScale(e.target.value)}
        style={{width: '100%'}}
      />
    </div>

    <div className="style-controls">
      <label className="main-label">
        Style:
      </label>

      <div
        className="select"
        name="style"
        onChange={e => e.target.checked ? setStyle(e.target.value) : null }
      >

        <select
          name="style"
          value={style}
          onChange={e => setStyle(e.currentTarget.value)}
        >
          <option value="backdrop" >
            Backdrop
          </option>

          { !logo.clone &&
            <option value="colorize" >
              Colorized
            </option>
          }

          <option value="glow" >
            Glow
          </option>

          <option value="shadow" >
            Shadow
          </option>

          <option value="none" >
            Unstyled
          </option>
        </select>
      </div>

      { style === 'backdrop' &&
        <div className="flex-row gap-2 ml-2">
          <a className="small link" onClick={toggleBackdropSettings} style={{display: 'block', padding: '0.5rem'}}>
            Customize
          </a>
          { backstage &&
            <div className="flex-row gap-1 hidden sm_flex">
              <div className="bg-white-transparent rounded-lg clickable border-gray shadow" style={{width: 38, height: 38}} title="White Background" onClick={() => {setBgColor('#FFFFFF'); if (bgOpacity.toString() === '90') setBgOpacity(95)}}/>
              <div className="bg-black-transparent rounded-lg clickable border-gray shadow" style={{width: 38, height: 38}} title="Black Background" onClick={() => {setBgColor('#000000'); if (bgOpacity.toString() === '95') setBgOpacity(90)}}/>
            </div>
          }
        </div>
      }

      { style === 'colorize' &&
        <p className="style-notes small subtle indented">
          <span className="style-note-intro">This style will</span> change
          the logo to match post colors
        </p>
      }

      { style === 'glow' &&
        <p className="style-notes small subtle indented">
          <span className="style-note-intro">Good for a dark logo,
          to </span> stand out on dark backgrounds
        </p>
      }

      { style === 'shadow' &&
        <p className="style-notes small subtle indented">
          <span className="style-note-intro">This style is</span> for logos
          with light colors (like white)
        </p>
      }

      { style === 'none' &&
        <p className="style-notes small subtle indented">
          <span className="style-note-intro">Only use this style </span> for logos
          that work on any background
        </p>
      }

    </div>


    <div className={classNames('backdrop-settings', {visible: style === 'backdrop' && showBackdropSettings})}>
      <div className='bg-slider center-vertically'>
        <label>Color:</label>
        <input
          type="color"
          value={bgColor}
          onChange={e => setBgColor(e.target.value)}
          style={{opacity: backdrop ? 1 : 0.3}}
        />
        <span className="bg-settings-value">{bgColor.toUpperCase()}</span>
      </div>

      <div className='bg-slider center-vertically'>
        <label>Opacity:</label>
        <input
          type="range"
          className="bg-setting-slider"
          value={bgOpacity}
          min="0"
          max="100"
          step="1"
          onChange={e => setBgOpacity(e.target.value)}
        />
        <span className="bg-settings-value">{bgOpacity}%</span>
      </div>

      <div className='bg-slider center-vertically'>
        <label>Padding:</label>
        <input
          type="range"
          className="bg-setting-slider"
          value={bgPadding}
          min="0  "
          max="100"
          step="1"
          onChange={e => setBgPadding(e.target.value)}
        />
        <span className="bg-settings-value">{bgPadding}px</span>
      </div>

      <div className='bg-slider center-vertically'>
        <label>Rounding:</label>
        <input
          type="range"
          className="bg-setting-slider"
          value={bgRounding}
          min="0"
          max="100"
          step="1"
          onChange={e => setBgRounding(e.target.value)}
        />
        <span className="bg-settings-value">{bgRounding}px</span>
      </div>
    </div>


    <div className="center-text" style={{marginTop: '1rem'}}>
        { hasChanges ?
          <>
            <button className="button is-primary" onClick={handleSave}>
              Save
            </button> &nbsp;
            <button className="button" onClick={handleCancel}>
              Cancel
            </button>
          </>
        :
          <>
            <button className="button is-primary" onClick={handleCancel}>
              Close
            </button>
          </>
        }
    </div>

    <style jsx>{`
      label {
        margin-right: 0.25rem;
      }
      .subtle {
        line-height: 1.1rem;
      }
      .remove-logo {
        background-color: #ffffffcc;
        padding: 0px 6px 4px;
        border-radius: 2px;
        cursor: pointer;
        vertical-align: top;
        border: 1px solid #ffaaaa99;
        margin-bottom: 0.5rem;
      }
      .remove-logo:hover {
        background-color: #ffcccccc;
        border-color: #ffaaaa;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      }
      .button {
        min-width: 6rem;
      }
      .main-label {
        line-height: 2rem;
        min-width: 3rem;
        font-weight: bold;
        font-size: 1rem;
      }
      .disabled .bg-slider,
      .disabled .bg-settings-value {
        color: #aaa;
      }
      .style-controls {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        max-width: 400px;
      }
      .backdrop-settings {
        margin-left: 3.5rem;
        overflow-y: hidden;
        max-height: 0px;
        transition: max-height 500ms ease;
      }
      .backdrop-settings.visible {
        max-height: 140px;
      }
      .bg-slider {
        margin: 0.6rem 0 0.3rem;
        width: 100%;
        font-size: 0.9rem;
      }
      .bg-slider .bg-setting-slider {
        flex-grow: 1;
        max-width: 40%;
      }
      .bg-settings-value {
        min-width: 2.75rem;
        display: inline-block;
        margin-left: 0.5rem;
      }
      .bg-slider label {
        display: inline-block;
        min-width: 4rem;
        white-space: nowrap;
      }
      .style-tip {
        text-align: center;
        margin-top: -4px;
        margin-bottom: 3px;
      }
      .style-note-intro {
        display: none;
      }
      @media only screen and (max-width: 320px) {
        .style-notes {
          font-size: 0.55rem;
          line-height: 0.85rem;
        }
      }
      @media only screen and (max-width: 374px) {
        .style-tip {
          font-size: 0.6rem;
        }
      }
      @media only screen and (min-width: 475px) {
        .style-tip {
          font-size: 0.9rem;
        }
        .style-note-intro {
          display: inline;
        }
      }
    `}</style>
  </>
}


function LogoPreview ({logo, logoProps, align, vAlign, logoX, logoY, photoUrl, colorizeFilter}) {
  const { scale, style, bgColor, bgOpacity, bgPadding, bgRounding } = logoProps

  const margin = style === 'backdrop' ? parseInt(bgPadding, 10) : 0

  // Values for the logo position and size
  const width  = logo.width * scale
  const height = logo.height * scale
  const x = logoX + (align === 'center' ? -width / 2 : margin)
  const y = logoY + (vAlign === 'middle' ? -height / 2 : -height - margin)

  // Values for the size of the post image
  const originalWidth = 1200
  const originalHeight = 630
  const imageWidth = 400
  const imageScale = originalWidth / imageWidth
  const imageHeight = originalHeight / imageScale

  const logoFilter = (
    (style === 'colorize') ? colorizeFilter :
    (style === 'shadow')   ? shadowFilter :
    (style === 'glow')     ? glowFilter :
    ''
  )

  return <>
    <div className="post-preview">
      <img
        width={imageWidth}
        height={imageHeight}
        alt="Sample Post"
        src={photoUrl}
      />
      <div
        className="backdrop"
        style={{
          background: RGBA(bgColor, bgOpacity),
          borderRadius: bgRounding / imageScale,
          left:   ((x      - margin) / imageScale) / imageWidth * 100 + '%',
          top:    ((y      - margin) / imageScale) / imageHeight * 100 + '%',
          width:  ((width  + margin * 2) / imageScale) / imageWidth * 100 + '%',
          height: ((height + margin * 2) / imageScale) / imageHeight * 100 + '%',
          display: style === 'backdrop' ? 'block' : 'none',
        }}
      />
      <img
        alt="Logo Preview"
        className="logo"
        src={logo.url}
        style={{
          left:   (x      / imageScale) / imageWidth * 100 + '%',
          top:    (y      / imageScale) / imageHeight * 100 + '%',
          width:  (width  / imageScale) / imageWidth * 100 + '%',
          height: (height / imageScale) / imageHeight * 100 + '%',
          filter: logoFilter,
        }}
      />

    </div>

    <style jsx>{`
      .post-preview {
        position: relative;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        display: grid;
        margin-bottom: 0.5rem;
      }
      .backdrop {
        position: absolute;
      }
      .logo {
        backface-visibility: hidden;
      }
      .logo, .logo-color {
        position: absolute;
      }
    `}</style>
  </>
}


function LogoDropZone({onUpload, onStart, onStop}) {

  const [uploadStatus, setUploadStatus] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const {touch} = useDeviceInfo()

  const handleDrop = useCallback(async files => {

    if (!files.length) return

    let cloudFile = null
    const uploadPortionOfProgresss = 0.30

    try {
      onStart()
      cloudFile = await uploadFileWithProgress(files[0], {progress: m => {
        const adjustedProgress = Math.round(m * uploadPortionOfProgresss)
        setUploadStatus(`Uploading...`)
        setUploadProgress(adjustedProgress)
      }})
    } catch (e) {
      onStop()
      sendMessage('error', "Logo upload failed. " + e.message)
      console.log(e)
      setUploadStatus('')
    }

    if (!cloudFile || !cloudFile.id) return

    try {
      const logo = await rpc('logos.add', {fileId: cloudFile.id})

      let fakeProgress = 100 * uploadPortionOfProgresss
      const timer = setInterval(() => {
        fakeProgress = Math.min(100, fakeProgress + 2)
        setUploadStatus(
          fakeProgress >= 100
          ? `Finalizing...`
          : `Checking Colors...`
        )
        setUploadProgress(fakeProgress)
      }, 100)
      await onUpload(logo)
      clearInterval(timer)
      setUploadStatus('')
    } catch (err) {
      console.error(err)
      throw Error('Could not attach logo.')
    } finally {
      onStop()
    }
  }, [setUploadStatus, onUpload])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop,
  })

  return <>
    <div className="upload-ui content">
      <div>
        For <u>best</u> results, upload a logo with:
        <ul className="ul">
          <li>High Resolution<span className="help">Ideally, over 600 pixels wide</span></li>
          <li>Transparent Background<span className="help">Solid backgrounds are ok, but less fun</span></li>
        </ul>
      </div>

      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classNames("drop-container clickable", {dragging: isDragActive})}>
          { uploadStatus ?
          <div className="padded">
            { uploadStatus }<br/>
            <div style={{marginTop: '0.5rem'}}>
              <ProgressBar value={uploadProgress}/>
            </div>
          </div>
          : touch ?
            <div>
              Tap here<br/>to upload your logo.
            </div>
          :
            <div>
              Drop your logo here, <br/>
              or tap here to browse.
            </div>
          }
        </div>
      </div>

    </div>

    <style jsx>{`
      li {
        margin-bottom: 1rem;
      }
      .upload-ui {
        max-width: 300px;
      }
      .drop-container {
        height: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f3f3f3;
        border: 2px dashed #ccc;
        color: #999;
        cursor: pointer;
        margin: 0.5rem 0;
      }
      .dragging {
        background-color: rgb(209, 242, 253);
      }
    `}</style>
  </>
}


function RGBA(hexColor, opacity) {
  return hexColor + Math.round(opacity / 100 * 255).toString(16).padStart(2, '0')
}
