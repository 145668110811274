export default function DeleteIcon ({color = 'red'}) {

  return <>
    <svg className="icon" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" fill="none" strokeWidth="2" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round">
        <path d="M8,6 L9,1 L15,1 L16,6"/>
        <path d="M1,6 L23,6"/>
        <path d="M3,6 L5,23 L19,23 L21,6"/>
      </g>
      <g stroke="currentColor" fill="none" strokeWidth="1.5" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round">
        <path d="M8.75,10 L9.75,19"/>
        <path d="M15.25,10 L14.25,19"/>
      </g>
    </svg>

    <style jsx>{`
      .icon {
        color: ${color};
        margin: auto;
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    `}</style>
  </>
}
